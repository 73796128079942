<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-row v-if="!loadingData">
                    <b-col class="text-center">
                        <p>{{ (currentLocale === 'en') ? 'Loading! Please wait...' : 'লোড হচ্ছে! অনুগ্রহপূর্বক অপেক্ষা করুন...' }}</p>
                        <h1><i class="fas fa-spinner fa-pulse"></i></h1>
                    </b-col>
                </b-row>
                <b-row v-if="userList.length > 0">
                    <b-col lg="12" sm="12">
                         <b-row>
                            <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                                <b-button class="text-right" @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xl="12" sm="12">
                                <b-row>
                                    <b-col md=12>
                                             <h5 class="mb-1">{{ $n(1) + '. ' + $t('research_manage.project_info') }}</h5>
                                        <b-table-simple striped bordered small hover>
                                                <b-tbody>
                                                     <b-tr>
                                                       <b-th class="text-left">{{$t('external_research.project_id')}}</b-th>
                                                       <b-td class="text-left">{{ proposal.project.proposal_auto_id }}</b-td>
                                                    </b-tr>
                                                     <b-tr>
                                                        <b-th class="text-left">{{$t('external_research.project_title')}}</b-th>
                                                        <b-td class="text-left">{{ ($i18n.locale==='en') ? proposal.project.project_title : proposal.project.project_title_bn }}</b-td>
                                                    </b-tr>
                                                     <b-tr>
                                                        <b-th class="text-left">{{$t('external_research.thematic_area')}}</b-th>
                                                        <b-td class="text-left">{{ getThematicAreaList(proposal.thematic_area_id) }}</b-td>
                                                    </b-tr>
                                                </b-tbody>
                                        </b-table-simple>
                                             <h5 class="mb-1">{{ $n(2) + '. ' + $t('research_manage.objectives') + ' :' }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <tbody v-if="proposal.project.description">
                                            <b-tr v-for="(item, index) in proposal.project.description.details" :key="index">
                                                <b-td width="20%" class="text-center">{{ $n(index + 1) }}</b-td>
                                                <b-td colspan="3">{{ currentLocale == 'en' ? item.specific_objective : item.specific_objective_bn }}</b-td>
                                            </b-tr>
                                        </tbody>
                                        </b-table-simple>
                                             <h5 class="mb-1">{{ $n(3) + '. ' + $t('external_research.implementation_location') + ' ' + $t('sidebar.information') + ':' }}</h5>
                                        <b-table-simple v-if="proposal.project.locations" striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="10%">{{ $t('globalTrans.sl_no') }}</th>
                                                    <!-- <th width="30%" scope="col">{{ $t('globalTrans.org_name') }}</th> -->
                                                    <th width="30%" scope="col">{{ $t('external_research.division_section') }}</th>
                                                    <th width="30%" scope="col">{{ $t('globalTrans.address') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.project.locations" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <!-- <b-td>{{ currentLocale == 'bn' ? item.org_name_bn : item.org_name }}</b-td> -->
                                                    <b-td>{{ currentLocale == 'bn' ? item.division_bn : item.division }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.address_bn : item.address }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                             <h5 class="mb-1">{{ $n(4) + '. ' + $t('research_manage.designation_leader_address') + ':' }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center mytd" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th scope="col">{{ $t('research_manage.org_or_univ_name') }}</th>
                                                    <th scope="col">{{ $t('external_research.principal_investigator_name') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.designation') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.address') }}</th>
                                                    <th scope="col">{{ $t('globalUserData.mobile_no') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.email') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody v-if="proposal.project.co_investigator.investigators.length > 0">
                                                <b-tr v-for="(item, index) in proposal.project.co_investigator.investigators" :key="index">
                                                    <b-td class="text-center mytd">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ getComponentOrgName(item.org_type, item.component_org_id) }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.investigator_name_bn : item.investigator_name }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.designation_bn : item.designation }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.address_bn : item.address }}</b-td>
                                                    <b-td>{{ item.mobile_no | mobileNumber}}</b-td>
                                                    <b-td>{{ item.email }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                            <h5 class="mb-1">{{ $n(5) + '. ' + $t('research_manage.major_activity') + ':' }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="10%">{{ $t('external_research.activity_no') }}</th>
                                                    <th scope="col">{{ $t('external_research.activity_name') }}</th>
                                                    <th class="text-center" scope="col">{{ $t('research_manage.duration') }}</th>
                                                    <th scope="col">{{ $t('research_manage.proposed_progress') }} (%)</th>
                                                    <th scope="col">{{ $t('research_manage.actual_progress') }} (%)</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.project.activities" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ currentLocale == 'bn' ? item.activity_name_bn : item.activity_name }}</b-td>
                                                    <b-td class="text-center">{{ item.start_date | dateFormat }} - {{ item.end_date | dateFormat }}</b-td>
                                                    <b-td>
                                                        <div v-for="(itm, indx) in item.docs.filter(el => el.is_external === 1)" :key="indx">
                                                            <span>{{ $t('globalTrans.date') + ': ' }} {{ itm.created_at | dateFormat }}, </span>
                                                            <span>{{ $t('research_manage.progress') + ': ' + $n(itm.progress_percentage) }}</span>
                                                        </div>
                                                    </b-td>
                                                    <b-td>
                                                        <div v-for="(itm, indx) in item.docs.filter(el => el.is_external === 0)" :key="indx">
                                                            <span>{{ $t('globalTrans.date') + ': ' }} {{ itm.created_at | dateFormat }}, </span>
                                                            <span>{{ $t('research_manage.progress') + ': ' + $n(itm.progress_percentage) }}</span>
                                                        </div>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                            <h5 class="mb-1">{{ $n(6) + '. ' + $t('external_research.budget_info') }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                                                    <th scope="col">{{ $t('research_manage.budget_type') }}</th>
                                                    <th scope="col">{{ $t('external_research.budget_head') }}</th>
                                                    <th scope="col">{{ $t('globalTrans.code') }}</th>
                                                    <th scope="col">{{ $t('external_research.expense_year') }}</th>
                                                    <th scope="col">{{ $t('external_research.budget_percentage') }}</th>
                                                    <th scope="col">{{ $t('external_research.allocation_amount') + '' + $t('globalTrans.taka') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.project.budget.details" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td>{{ getBudgetTypeName(item.budget_type_id) }}</b-td>
                                                    <b-td>{{ getBudgetName(item) }}</b-td>
                                                    <b-td>{{ $n(item.code, { useGrouping: false }) }}</b-td>
                                                    <b-td>{{ getExpenseYearName(item.expense_year) }}</b-td>
                                                    <b-td>{{ $n(item.budget_percentage) }}</b-td>
                                                    <b-td>{{ Number.isInteger(parseFloat(item.allocation_amount)) ? ($n(item.allocation_amount) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(item.allocation_amount) }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td class="align-middle" colspan="2">
                                                        <p class="text-left m-0 p-0">{{$t('external_research.target_budget') + ' ' + $t('globalTrans.taka') + ' = ' + $n(proposal.project.budget.target_budget) }}</p>
                                                    </b-td>
                                                    <b-td colspan="4">
                                                        <p class="text-right m-0 p-0">{{ $t('external_research.total_budget') + ' (%)' }}</p>
                                                        <p class="text-right">{{ $t('external_research.total_budget') + ' ' + $t('globalTrans.taka') }}</p>
                                                    </b-td>
                                                    <b-td>
                                                    <p class="text-left m-0 p-0">{{ $n(proposal.project.budget.total_budget_perc) }}</p>
                                                    {{ Number.isInteger(parseFloat(proposal.project.budget.total_budget)) ? ($n(proposal.project.budget.total_budget) + (currentLocale === 'bn' ? '.০০' : '.00')) : $n(proposal.project.budget.total_budget) }}
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                            <h5 class="mb-1">{{ $n(7) + '. ' + $t('research_manage.constraints_suggestions') + ':' }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="50%">{{ $t('research_manage.constraints') }}</th>
                                                    <th class="text-center" scope="col">{{ $t('research_manage.suggestions') }}</th>
                                                </tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.project.activities" :key="index">
                                                    <b-td>{{ item.constraints }}</b-td>
                                                    <b-td>{{ item.suggestions }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                            <h5 class="mb-1">{{ $n(8) + '. ' + $t('research_manage.any_other_information') + ':' }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.project.activities.filter(el => el.other_information !== null)" :key="index">
                                                    <b-td>{{ item.other_information }}</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                             <h5 class="mb-1">{{ $n(9) + '. ' + $t('research_manage.name_signature_interviewed') + ' :' }}</h5>
                                        <b-table-simple bordered small hover>
                                            <b-tbody>
                                                <b-tr><b-td><br><br></b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                             <h5 class="mb-1">{{ $n(10) + '. ' + $t('research_manage.sign_monitoring_team') + ' :' }}</h5>
                                        <b-table-simple striped bordered small hover>
                                            <thead>
                                                <b-tr>
                                                    <b-td colspan="7" class="text-center">{{ getOrgName(proposal.monitoring_team_member.org_id) }} <br/> {{ getFiscalYearList(proposal.monitoring_team_member.fiscal_year_id) }} <br/> {{ getMonitoringTeam(proposal.monitoring_team_member.team_id) }}</b-td>
                                                </b-tr>
                                            </thead>
                                            <b-tbody>
                                                <b-tr v-for="(item, index) in proposal.monitoring_team_member.members" :key="index">
                                                    <b-td colspan="6" class="text-left">{{ getTeamMember(item.user_id) }} <br/>
                                                    {{ getRoleName(item.master_role_id) }} <br/>
                                                    {{ getDesignationName(item.designation_id) }} <br/>
                                                    {{ item.mobile_no | mobileNumber }} <br/>
                                                    {{ getOrganization(item.org_id) }}</b-td>
                                                    <b-td width="400px"></b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringReportShowApi } from '../../api/routes'
import ExportPdf from './export_pdf_details'
export default {
    props: ['id'],
    data () {
        return {
            loadingData: false,
            proposal: {},
            userList: []
        }
    },
    created () {
        if (this.id) {
            this.loadData()
        }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      }
    },
    methods: {
        getOrgName (Id) {
        const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === Id)
        if (this.$i18n.locale === 'en') {
            return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getObjectiveName (Id) {
        if (this.proposal.description.details.length > 0) {
            const Obj = this.proposal.description.details.find(item => item.id === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                return Obj.specific_objective
            } else {
                return Obj.specific_objective_bn
            }
        }
        },
        getBudgetTypeName (Id) {
            const Obj = this.$store.state.AgriResearch.commonObj.budgetTypeList.find(item => item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getBudgetName (myItem) {
            const Obj = this.$store.state.AgriResearch.commonObj.budgetHeadList.find(item => item.value === parseInt(myItem.budget_head_id))
            myItem.code = Obj.budget_head_code
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
            }
        },
        getOrganization (orgId) {
        const orgName = this.$store.state.orgList.find(item => item.value === orgId)
        if (orgName === undefined) return ''
        return this.$i18n.locale === 'bn' ? orgName.text_bn : orgName.text_en
        },
        getExpenseYearName (Id) {
        const Obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === Id)
        if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getFiscalYearList (Id) {
        const Obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === Id)
        if (this.$i18n.locale === 'en') {
        return Obj.text_en
        } else {
        return Obj.text_bn
        }
        },
        getThematicAreaList (Id) {
        const Obj = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === Id)
        if (this.$i18n.locale === 'en') {
        return Obj.text_en
        } else {
        return Obj.text_bn
        }
        },
        getMonitoringTeam (monitoringId) {
        const team = this.$store.state.AgriResearch.commonObj.monitoringTeamList.find(item => item.value === monitoringId)
        if (team === undefined) return ''
        return this.$i18n.locale === 'bn' ? team.text_bn : team.text_en
        },
        getResearchType (monitoringId) {
        const team = this.$store.state.AgriResearch.commonObj.researchTypeList.find(item => item.value === monitoringId)
        if (team === undefined) return ''
        return this.$i18n.locale === 'bn' ? team.text_bn : team.text_en
        },
        getDesignationName (designationId) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === designationId)
        if (designation === undefined) return ''
        return this.$i18n.locale === 'bn' ? designation.text_bn : designation.text_en
        },
        getRoleName (roleId) {
        const role = this.$store.state.AgriResearch.commonObj.roleInformationList.find(item => item.value === roleId)
        if (role === undefined) return ''
        return this.$i18n.locale === 'bn' ? role.text_bn : role.text_en
        },
        getTeamMember (userId) {
        const user = this.userList.find(item => item.value === parseInt(userId))
        if (user === undefined) return ''
        return this.$i18n.locale === 'bn' ? user.text_bn : user.text_en
        },
        getComponentOrgName (type, Id) {
        if (type === 1) {
            const newObj = this.$store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === Id)
            const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === newObj.org_id)
            return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
        } else {
            const Obj = this.$store.state.AgriResearch.commonObj.componentOrgList.find(item => item.value === Id)
            return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
        }
        },
        loadData () {
            RestApi.getData(agriResearchServiceBaseUrl, `${monitoringReportShowApi}/${this.id}`).then(response => {
                if (response.success) {
                     this.proposal = response.data
                     this.userList = response.user_details
                     this.loadingData = true
                }
                // this.loadingData = false
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        pdfExport () {
        const reportTitle = this.$t('research_manage.monitoring_report') + ' ' + this.$t('globalTrans.details')
        ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, this.proposal, this)
        }
    }
}
</script>
<style lang="scss">
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .contentTitle {
      font-size: 15px; background-color: #005B5B; padding: 1px
  }
</style>
